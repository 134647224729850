













































































































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { CourseResponseCourses } from '../../../../types/typescript-axios/api';
import {
  isWaitingList,
  courseStatusText,
  courseTimeStatus,
  mansekiStatus,
  isReservedStatus,
  isReservedStatus2,
  isReservedStatus3,
  isCourseApplicationMethod,
  isCancelNoteStatus,
} from '../../../plugins/helper';
import LabelStatus from '@/components/contents/label/LabelStatus.vue';

@Component({
  components: {
    LabelStatus,
  },
})
export default class CourseReserveButton extends Vue {
  @Prop({default: {}})
  private course!: CourseResponseCourses;

  private get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }

  private get isTimeStatus(): number {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return courseTimeStatus(this.course);
  }

  private get isWaitngListStatus(): boolean {
    return isWaitingList(this.course); // キャンセル待ちする？
  }

  private get isStatus1(): number {
    // -1: 未申し込み
    // 0: 申込受付
    // 1: キャンセル
    // 2: キャンセル待ち
    return isReservedStatus(this.course);
  }

  private get isStatus2(): number {
    // 講座の予約ステータス2
    // -1: 未申し込み
    // 0: -
    // 1: キャンセル待ち繰り上げ
    // 2: 繰り上げ
    // 3: キャンセル料発生
    // 4: ドタキャン（キャンセル料発生）
    // 5: キャンセル待ち辞退
    return isReservedStatus2(this.course);
  }

  private get isStatus3(): number {
    // 講座の支払い状況
    // -1: 未申し込み
    // 0: -
    // 1: 未入金
    // 2: 入金済
    return isReservedStatus3(this.course);
  }

  private get isManseki(): boolean { // 満席？
    return mansekiStatus(this.course);
  }

  private get isApplicationMethod(): number {
    // 申込方法
    // 先着順 0
    // 抽選 1
    // オンライン 2
    return isCourseApplicationMethod(this.course); // 申込み方法
  }

  private get isButtonHidden(): boolean {
    return Boolean(Number(this.course.data2.acf?.orderBtnHidden));
  }

  private get isButtonStatus(): number {

    if (this.isTimeStatus === 3) { // 終了
      // この講座は終了しました。
      return 0.2; // ラベル表示
    }

    if (this.isTimeStatus < 3) {
      if (
        this.isStatus1 === 0 // 申込受付
        ||
        (
          this.isStatus1 === 2 && // キャンセル待ち
          this.isStatus2 !== 2 // 繰り上げ確認中以外
        )
      ) {
        return 4; // 申込みキャンセル
      }
      if (this.isStatus2 === 2) { // 繰り上げ確認中
        return -1; // 申込みキャンセル
      }
    }

    if (
      this.isTimeStatus === 0 // 申込み受付期間前
    ) {
      // 受付開始までお待ちください。
      return 0; // ラベル表示
    }

    if (this.isTimeStatus === 2) { // 申込み期間終了
      // 申込受付を終了しました。
      return 0.1; // ラベル表示
    }

    if (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        (this.isStatus1 === -1 || this.isStatus1 === 1) && // 未申込み・キャンセル
        this.isManseki && // 満席
        !this.isWaitngListStatus // キャンセル待ち受付しない
    ) {
      // 申込受付を終了しました。
      return 0.1; // ラベル表示
    }

    if (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        (this.isStatus1 === -1 || this.isStatus1 === 1) && // 未申込み・キャンセル
        this.isManseki && // 満席
        this.isWaitngListStatus // キャンセル待ち受付
    ) {
      return 3; // この講座に申込む(キャンセル待ち)
    }

    if (
      (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === -1 && // 未申込み
        !this.isManseki && // 未満席
        !this.isLogin // 未ログイン
      ) ||
      (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        this.isStatus1 === -1 && // 未申込み
        this.isManseki && // 満席
        this.isWaitngListStatus && // キャンセル待ち受付
        !this.isLogin // 未ログイン
      ) ||
      (
        this.isTimeStatus === 1 && // 受付期間中
        (this.isApplicationMethod === 1 || this.isApplicationMethod === 2) && // 抽選・オンライン
        this.isStatus1 === -1 && // 未申込み
        !this.isLogin // 未ログイン
      )
    ) {
      return 1; // この講座に申込む（MOC会員の方）
    }

    if (
      (
        this.isTimeStatus === 1 && // 受付期間中
        this.isApplicationMethod === 0 && // 先着順
        (this.isStatus1 === -1 || this.isStatus1 === 1) && // 未申込み・キャンセル
        !this.isManseki && // 未満席
        this.isLogin // ログイン中
      ) ||
      (
        this.isTimeStatus === 1 && // 受付期間中
        (this.isApplicationMethod === 1 || this.isApplicationMethod === 2) && // 抽選・オンライン
        (this.isStatus1 === -1 || this.isStatus1 === 1) && // 未申込み
        this.isLogin // ログイン中
      )
    ) {
      return 2; // この講座に申込む
    }

    return -1; // ラベル表示
  }

  private get isNoteStatus(): number {
    if (
        this.isButtonStatus === 1 ||
        (this.isButtonStatus === 3 && !this.isLogin)
    ) {
      /**
       * MOC会員登録（無料）はこちらから
       */
      return 3;
    }

    return isCancelNoteStatus(this.course);

  }

  @Emit()
  private brReplace(item: string) {
    let result = '';
    result = item.replace( /\n|\r\n|\r/g, '<br>' );
    return result;
  }
  @Emit()
  private formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
}
